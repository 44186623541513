import {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {db} from 'providers/firebase'
import {ref, update, onValue} from 'firebase/database'

import {
	Alert,
	Avatar,
	Box,
	Button,
	Chip,
	Collapse,
	IconButton,
	Paper,
	Stack,
	Tooltip,
	Typography,
	useTheme
} from '@mui/material'

import {
	DataGrid,
	GridActionsCellItem,
	GridEditInputCell,
	GridFooterContainer,
	GridPagination,
	GridRowEditStopReasons,
	GridRowModes
} from '@mui/x-data-grid'

import {
	Add,
	Check,
	Clear,
	Delete,
	Edit,
	Save
} from '@mui/icons-material'


export default () => {

	return (
		<Box>
			This section is for the management of staff, staff roles and associated data
		</Box>
	)
}