import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useController, useForm} from 'react-hook-form'

import {
	Box,
	Divider,
	Paper,
	TextField as MuiTextField,
	Typography
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import {
	AdminPanelSettings,
	Article,
	ContactEmergency,
	ContactPhone,
	Person
} from '@mui/icons-material'

import SectionLink from 'components/SectionLink'
import StickySidebar from 'components/StickySidebar'
import FormBox from 'components/forms/FormBox'

import CheckPermission, {checkPermission} from 'functions/CheckPermission'

import TextField from 'components/forms/TextField'
import DateOfBirth from 'components/forms/staffMembers/DateOfBirth'
import StartDate from 'components/forms/staffMembers/StartDate'


//~
const descriptions = {
	eventImage: (<>
		<Typography>
			Image / artwork associated with the event.
		</Typography>
	</>),
}

function Section ({
	children,
	descriptionProps = {},
	hideDescriptions = false,
	id,
	name,
	sx,
	...props
}) {
	return (
		<Paper
			id={id}
			sx={{
				padding: 2,
				display: 'flex',
				flexDirection: 'column',
				gap: 2,
				...sx
			}}
			variant='outlined'
			{...props}
		>
			{name && (
				<Typography
					component='h2'
					variant='h5'
				>
					{name}
				</Typography>
			)}
			{(!hideDescriptions && descriptions[id]) && (
				<Box
					display='flex'
					flexDirection='column'
					gap={1}
					id={`${id}-description`}
					marginBottom={1}
					{...descriptionProps}
				>
					{descriptions[id]}
				</Box>
			)}
			{children}
		</Paper>
	)
}
//



export default function AddStaffMember() {
	const {verifiedUser} = useSelector(state => state.auth)

	const userCanSeeSensitiveData = checkPermission({
		user: verifiedUser,
		permission: 'viewPersonnelSensitive'
	})

	const {
		control,
		handleSubmit,
		setValue,
		setError,
		clearErrors,
		formState: {
			errors
		},
		watch
	} = useForm({
		mode: 'all'
	})

	const onSubmit = async data => {
		console.log('submit', data)
	}

	return (
		<Box sx ={{
			display: 'flex',
			alignItems: 'flex-start',
			gap: {
				xs: 1,
				md: 2
			}
		}}>
			<StickySidebar>
				<SectionLink
					icon={<Person />}
					name='Basic Information'
					target='#basic-information'
				/>
				<SectionLink
					icon={<ContactPhone />}
					name='Contact Details'
					target='#contact-details'
				/>
				<SectionLink
					icon={<AdminPanelSettings />}
					name='Sensitive Information'
					target='#sensitive-information'
					disabled={!userCanSeeSensitiveData}
				/>
				<SectionLink
					icon={<Article />}
					name='Document dates etc'
					target='#document-dates'
				/>
			</StickySidebar>

			<Box
				component='form'
				autoComplete='off'
				onSubmit={handleSubmit(onSubmit)}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-evenly',
					gap: {
						xs: 1,
						md: 2
					},
					flexGrow: 1,
					maxWidth: {
						xs: '100%',
						lg: '960px'
					}
				}}
			>
				<Section
					id='basic-information'
					name='Basic Information'
				>
					<FormBox>
						<TextField
							control={control}
							label='First Name'
							name='firstName'
							error={errors?.firstName}
							required
							rules={{
								maxLength: {
									value: 64,
									message: 'Max length: 64'
								}
							}}
						/>

						<TextField
							control={control}
							label='Surname'
							name='surname'
							error={errors?.surname}
							required
							rules={{
								maxLength: {
									value: 64,
									message: 'Max length: 64'
								}
							}}
						/>
					</FormBox>

					<FormBox>
						<DateOfBirth
							control={control}
							error={errors?.dateOfBirth}
							name='dateOfBirth'
						/>
					</FormBox>

					<FormBox>
						<StartDate
							control={control}
							error={errors?.startDate}
							name='startDate'
						/>
					</FormBox>
				</Section>

				<Section
					id='contact-details'
					name='Contact Details'
				>
					<FormBox>
						<TextField
							type='tel'
							control={control}
							label='Phone Number'
							name='phoneNumber'
						/>
					</FormBox>

					<FormBox>
						<TextField
							type='email'
							control={control}
							error={errors?.emailPersonal}
							label='Email Address (personal)'
							name='emailPersonal'
							rules={{
								pattern: {
									value: /^(.+)\@(.+)\.(.+)$/,
									message: 'Invalid email address'
								}
							}}
						/>
					</FormBox>

					<FormBox>
						<TextField
							type='email'
							control={control}
							error={errors?.emailCompany} required
							label='Email Address (company)'
							name='emailCompany'
							rules={{
								pattern: {
									value: /^(.+)\@(.+)\.(.+)$/,
									message: 'Invalid email address'
								}
							}}
						/>
					</FormBox>

					<Divider />

					<FormBox>
						<TextField
							control={control}
							label='Address Line 1'
							name='addressLineOne'
							error={errors?.addressLineOne}
							rules={{
								maxLength: {
									value: 64,
									message: 'Max length: 64'
								}
							}}
						/>
					</FormBox>

					<FormBox>
						<TextField
							control={control}
							label='Address Line 2'
							name='addressLineTwo'
							error={errors?.addressLineTwo}
							rules={{
								maxLength: {
									value: 64,
									message: 'Max length: 64'
								}
							}}
						/>
					</FormBox>

					<FormBox>
						<TextField
							control={control}
							label='Town / City'
							name='addressTown'
							error={errors?.addressTown}
							rules={{
								maxLength: {
									value: 64,
									message: 'Max length: 64'
								}
							}}
						/>
					</FormBox>

					<FormBox>
						<TextField
							control={control}
							label='County'
							name='addressCounty'
							error={errors?.addressCounty}
							rules={{
								maxLength: {
									value: 64,
									message: 'Max length: 64'
								}
							}}
						/>
					</FormBox>

					<FormBox>
						<TextField
							control={control}
							label='Postcode'
							name='addressPostcode'
							error={errors?.addressPostcode}
							rules={{
								maxLength: {
									value: 8,
									message: 'Max length: 8'
								}
							}}
						/>
					</FormBox>

				</Section>

				<Section
					id='sensitive-information'
					name='Sensitive Information'
				>
					ni number <br/>
					self employed?, utr<br />
					right to work?, !share code
					night vision, diabetes, epilepsy, allergies, other health, emergency contacts <br/>
				</Section>

				<Section
					id='document-dates'
					name='Document Dates'
				>

				</Section>
			</Box>
		</Box>
	)
}