import {configureStore, createListenerMiddleware} from '@reduxjs/toolkit'
import {setupListeners} from '@reduxjs/toolkit/query'

import loading, {setLoading} from 'providers/slices/loading'
import auth, {clearUser, setUser} from 'providers/slices/auth'
import userData, {clearUserData, setUserData} from 'providers/slices/userData'
import userPreferences from 'providers/slices/userPreferences'
import sidebar, {setOpen} from 'providers/slices/sidebar'
import events from 'providers/slices/events'
import pageTitle from 'providers/slices/pageTitle'
import pastEvents from 'providers/slices/pastEvents'

import verifyUser from 'functions/verifyUser'

import {permissionsSlugs} from 'data/permissions'


const listenerMiddleware = createListenerMiddleware()

listenerMiddleware.startListening({
	actionCreator: setLoading,
	effect: async action => {
		console.log(`[Loading]`, action.payload ? '+' : '-')
	}
})


listenerMiddleware.startListening({
	actionCreator: setUser,
	effect: async (action, {dispatch, getState}) => {
		const {auth} = getState()
		await verifyUser(dispatch, auth)
	}
})


// listenerMiddleware.startListening({
// 	actionCreator: setOpen,
// 	effect: (action, {dispatch}) => {
// 		console.log('x', action, dispatch)
// 	}
// })

listenerMiddleware.startListening({
	actionCreator: clearUser,
	effect: (action, {dispatch}) => {
		dispatch(clearUserData())
	}
})


listenerMiddleware.startListening({
	predicate: (action, currentState, previousState) => {
		switch (action.type) {
			case 'userData/setUserData': {
				const permissionsTest = permissionsSlugs.every(permission => currentState.userData.userData?.permissions?.[permission] === previousState.userData.userData?.permissions?.[permission])
				return !permissionsTest
			}
			default: {
				return false
			}
		}
	},
	effect: async (action, {dispatch}) => {
		await verifyUser(dispatch, auth)
	}
})


const store = configureStore({
	reducer: {
		auth,
		events,
		loading,
		pageTitle,
		pastEvents,
		sidebar,
		userData,
		userPreferences
	},
	middleware: getDefaultMiddleware => (
		getDefaultMiddleware().concat(
			listenerMiddleware.middleware
		)
	)
})

setupListeners(store.dispatch)

export default store