import {useEffect, useState} from 'react'
import {api} from 'functions/axios'
import {useForm} from 'react-hook-form'
import {getAuth} from 'providers/firebase'
import {makeCreateEventFormData} from 'functions/makeEventFormData'

import {
	Box,
	Divider
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import {
	ConfirmationNumberOutlined,
	ContactMailOutlined,
	EventNoteOutlined,
	Web
} from '@mui/icons-material'
import {Icon} from '@iconify/react'

import Section from 'components/events/Section'
import SubSection from 'components/events/SubSection'
import SectionLink from 'components/SectionLink'
import StickySidebar from 'components/StickySidebar'
import {defaultValues} from 'components/events/defaultValues'
import FormBox from 'components/forms/FormBox'
import SubmitDialog from 'components/events/SubmitDialog'
import {
	AgeRestriction,
	ContactEmail,
	ContactName,
	ContactPromoter,
	Description,
	DoorPrice,
	EndDate,
	EventName,
	FlagCheckbox,
	HeadlineArtist,
	HeadlineArtistPreview,
	HeadlineArtistStartTime,
	ImageUpload,
	Room,
	StartDate,
	Status,
	StatusPrivate,
	SupportArtists,
	TicketPrice,
	TicketUrl,
	Type,
	WebsiteEventName,
	YoutubeId
} from 'components/events/fields'


export default function AddNewEvent() {
	const [loading, setLoading] = useState(false)
	const [dialogOpen, setDialogOpen] = useState(false)
	const [apiResponse, setApiResponse] = useState({})
	const [formDisabled, setFormDisabled] = useState(false)

	const {
		control,
		handleSubmit,
		setValue,
		setError,
		clearErrors,
		formState: {
			errors
		},
		watch
	} = useForm({
		mode: 'all',
		defaultValues
	})


	useEffect(() => {
		(process.env.NODE_ENV !== 'production' && Object.keys(apiResponse || {}).length > 0) && console.log('apiResponse', apiResponse)
	}, [apiResponse])

	const eventType = watch('type')
	const hideFromWebsite = watch('hideFromWebsite')

	const onSubmit = async data => {
		setLoading(process.env.NODE_ENV === 'production')
		setFormDisabled(process.env.NODE_ENV === 'production')
		setApiResponse({})
		setDialogOpen(true)
		const token = await getAuth().currentUser.getIdToken(true)
		api({
			method: 'post',
			url: eventType === 'private' ? '/events/createPrivate' : '/events/create', //~
			headers: {
				token
			},
			data: makeCreateEventFormData(data)
		}).then(response => {
			setApiResponse(response.data)
		}).catch(response => {
			setApiResponse(response)
			setFormDisabled(false)
			console.error(response)
		}).finally(() => setLoading(false))
	}

	return (<>
		<Box sx={{
			display: 'flex',
			alignItems: 'flex-start',
			gap: {
				xs: 1,
				md: 2
			}
		}}>
			<StickySidebar>
				<SectionLink
					icon={<EventNoteOutlined fontSize='small' />}
					name='Basic Information'
					target='#basicInformation'
				/>
				{eventType !== 'private' && (<>
					<SectionLink
						icon={<Icon icon='mdi:guitar-electric' height='18' />}
						name='Performing Artists'
						target='#artistInformation'
					/>
					<SectionLink
						icon={<ConfirmationNumberOutlined fontSize='small' />}
						name='Ticketing & Entry'
						target='#ticketing'
					/>
					<SectionLink
						icon={<Web fontSize='small' />}
						name='Other Fields'
						target='#other'
					/>
					<SectionLink
						icon={<ContactMailOutlined fontSize='small' />}
						name='External Contact'
						target='#externalContact'
					/>
				</>)}
			</StickySidebar>

			<Box sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: {
					xs: 1,
					md: 2
				}
			}}>
				<form
					autoComplete='off'
					onSubmit={handleSubmit(onSubmit)}
				>
					<Box sx={{
						display: 'flex',
						flexFlow: 'column',
						justifyContent: 'space-evenly',
						gap: 2,
						maxWidth: '960px'
					}}>

						<Section
							id='basicInformation'
							name='Basic Information'
						>
							<EventName
								name='name'
								control={control}
								error={errors?.name}
								formtype='create'
							/>
							<Type
								name='type'
								control={control}
							/>
							{eventType === 'private' ? (
								<StatusPrivate
									name='status'
									control={control}
								/>
							) : (
								<Status
									name='status'
									control={control}
								/>
							)}
							<FormBox>
								<StartDate
									name='start'
									control={control}
									error={errors?.start}
								/>
								<EndDate
									name='end'
									control={control}
									error={errors?.end}
								/>
							</FormBox>
							<Room
								name='room'
								control={control}
							/>
						</Section>

						{eventType !== 'private' && (<>
							<Section
								id='artistInformation'
								name='Performing Artists'
							>
								<FormBox>
									<HeadlineArtist
										name='headlineArtist'
										control={control}
									/>
									<HeadlineArtistStartTime
										name='headlineArtistStartTime'
										control={control}
									/>
								</FormBox>
								<HeadlineArtistPreview control={control} />
								<SupportArtists
									name='supportArtists'
									control={control}
									setValue={setValue}
								/>
							</Section>


							<Section
								id='ticketing'
								name='Ticketing & Entry'
							>
								<AgeRestriction
									name='ageRestriction'
									control={control}
								/>
								<FormBox>
									<TicketPrice
										name='prices.ticket'
										control={control}
										error={errors?.prices?.ticket}
									/>
									<DoorPrice
										name='prices.door'
										control={control}
										error={errors?.prices?.door}
									/>
								</FormBox>
							</Section>


							<Section
								id='other'
								name='Other Fields'
							>
								<Box sx={{
									display: 'flex',
									flexDirection: 'column',
									gap: 2
								}}>
									<SubSection sx={{gap: 0}}>
										<FlagCheckbox
											control={control}
											label='Hide the event from public website listings'
											name='hideFromWebsite'
										/>

										<FlagCheckbox
											control={control}
											label='Hide the event from the "Upcoming Events" section of theliverooms.com'
											name='hideFromUpcoming'
											disabled={hideFromWebsite}
										/>

										<FlagCheckbox
											control={control}
											label='Hide the event from the Accessibilty Reservation form'
											name='hideFromAccessibilityForm'
										/>
									</SubSection>

									<Divider />

									<SubSection
										description='Optionally configure a custom display name for the event on the website(s) instead of the Event Name specified above.'
										disabled={hideFromWebsite}
										title='Website Display Name'
									>
										<WebsiteEventName
											name='displayName'
											control={control}
											error={errors?.displayName}
											disabled={hideFromWebsite}
										/>
									</SubSection>

									<Divider />

									<SubSection
										description='The primary ticket URL for the event.'
										disabled={hideFromWebsite}
										title='Ticket URL'
									>
										<TicketUrl
											control={control}
											disabled={hideFromWebsite}
											error={errors?.ticketUrl}
											name='ticketUrl'
										/>
									</SubSection>

									<Divider />

									<SubSection
										description="A description of the event, e.g the artist's bio. This field can also be used to include any information or details relevant to the event that isn't covered by any of the other fields."
										disabled={hideFromWebsite}
										title='Description'
									>
										<Description
											name='description'
											control={control}
											error={errors?.description}
											disabled={hideFromWebsite}
										/>
									</SubSection>

									<Divider />

									<SubSection
										description="An image of the performing artist, their logo, or other artwork associated with the event."
										disabled={hideFromWebsite}
										title='Image'
									>
										<ImageUpload
											name='image'
											control={control}
											error={errors?.image}
											setError={setError}
											clearErrors={clearErrors}
											disabled={hideFromWebsite}
										/>
									</SubSection>

									<Divider />

									<SubSection
										description='The ID of A YouTube video to be included with the event listing.'
										disabled={hideFromWebsite}
										title='YouTube Video'
									>
										<YoutubeId
											control={control}
											disabled={hideFromWebsite}
											error={errors?.youtubeId}
											name='youtubeId'
										/>
									</SubSection>
								</Box>
							</Section>
							

							<Section
								id='externalContact'
								name='External Contact'
							>
								<ContactPromoter
									name='externalContact.promoter'
									control={control}
									error={errors?.externalContact?.promoter}
								/>
								<ContactName
									name='externalContact.name'
									control={control}
									error={errors?.externalContact?.name}
								/>
								<ContactEmail
									name='externalContact.email'
									control={control}
									error={errors?.externalContact?.email}
								/>
							</Section>
						</>)}


						<Box>
							<LoadingButton
								disabled={formDisabled}
								loading={loading}
								variant='contained'
								color='primary'
								type='submit'
							>
								Create Event
							</LoadingButton>
						</Box>

					</Box>
				</form>

				<SubmitDialog
					open={dialogOpen}
					setOpen={setDialogOpen}
					type='create'
					response={apiResponse}
					loading={loading}
				/>
			</Box>
		</Box>

		{process.env.NODE_ENV === 'development' && (
			<Section name='notes' sx={{mt: 4}}>
				<ul>
					<li>
						flags
						<ul>
							<li>tribute</li>
							<li>don't list times publicly</li>
							<li>free entry (no tickets)</li>
							<li>free entry (reservations)</li>
						</ul>
					</li>
					<li>
						announcing
						<ul>
							<li>
								announce date and time
							</li>
							<li>
								automatic change of event status
							</li>
						</ul>
					</li>
					<li>
						tickets
						<ul>
							<li>ticketurl -&gt; entry/ticketing	</li>
							<li>input -&gt; array</li>
							<li>outlet dropdown</li>
							<li>on sale time - app api will have this (for countdown), and omit ticket links</li>
							<li>flags<ul>
								<li>explicit 'not on sale' option (checkbox)</li>
								<li>explicit 'pay on door' option (checkbox)</li>
							</ul>
							</li>
						</ul>
					</li>
					<li>
						timings
						<ul>
							<li>start and end time, as it is now</li>
							<li>load-in, crew, sx + lx call times</li>
							<li>bar + 'other staff' call times</li>
						</ul>
					</li>
					<li>
						documents (for each band)
						<ul>
							<li>stage plan</li>
							<li>tech spec</li>
							<li>rider</li>
							<li>misc (for agreements, etc)</li>
						</ul>
					</li>
				</ul>
			</Section>
		)}
	</>)
}