import {useEffect} from 'react'
import {db} from 'providers/firebase'
import {onValue, orderByChild, ref, query, startAt} from 'firebase/database'
import {useDispatch, useSelector} from 'react-redux'
import {
	clearEvents,
	setEvents,
	setError,
	setLoading
} from 'providers/slices/events'


const requiredPermissions = ['viewEvents', 'manageEvents']

export default () => {
	const dispatch = useDispatch()
	const {
		loading: authLoading,
		verifiedUser
	 } = useSelector(state => state.auth)

	useEffect(() => {
		const now = Date.now()

		if (!authLoading && verifiedUser?.permissions && requiredPermissions.some(x => verifiedUser.permissions.includes(x))) {
			onValue(query(
				ref(db, '/events'),
				orderByChild('start'),
				startAt(now)
			), snapshot => {
				const events = []
				snapshot.forEach(event => {
					events.push(event.val())
				})
				dispatch(setEvents(events))
			}, error => {
				dispatch(setError(error))
			})
		} else {
			dispatch(clearEvents())
		}
	}, [verifiedUser?.permissions])
}