import {useController} from 'react-hook-form'
import {
	Checkbox,
	FormControlLabel
} from '@mui/material'
import FormBox from 'components/forms/FormBox'

export default ({
	control,
	label,
	name,
	...props
}) => {
	const {field} = useController({
		name,
		control
	})

	return (
		<FormBox>
			<FormControlLabel
				control={
					<Checkbox
						checked={field.value}
						{...field}
					/>
				}
				label={label}
				{...props}
			/>
		</FormBox>
	)
}