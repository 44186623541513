import {useController} from 'react-hook-form'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'


export default function DateOfBirth({
	control,
	error,
	name,
}) {
	const {
		field: {
			onBlur,
			...field
		}
	} = useController({
		control,
		name,
		rules: {
			required: 'Date of birth is required.',
			validate: {
				disableFuture: value => !dayjs(value).isAfter(dayjs()) || 'Date of birth cannot be in the future.'
			}
		}
	})

	return (
		<DatePicker
			{...field}
			disableFuture
			disableHighlightToday
			formatDensity='spacious'
			label='Date of Birth'
			openTo='year'
			required
			slotProps={{
				textField: {
					onBlur,
					error: !!error,
					fullWidth: true,
					helperText: error?.message,
					mask: '__/__/____',
					required: true,
					size: 'small'
				}
			}}
			variant='outlined'
			views={['year', 'month', 'day']}
		/>
	)
}