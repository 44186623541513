import {useController} from 'react-hook-form'
import {TextField as MuiTextField} from '@mui/material'


export default function TextField ({
	control,
	error,
	label,
	name,
	required = false,
	rules,
	...props
}) {
	const {field} = useController({
		name,
		control,
		rules
	})

	return (
		<MuiTextField
			{...field}
			fullWidth
			size='small'
			variant='outlined'
			label={label}
			error={!!error}
			helperText={error?.message}
			required={required}
			{...props}
		/>
	)
}