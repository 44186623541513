import {useEffect} from 'react'
import {useController} from 'react-hook-form'
import {TextField} from '@mui/material'
import FormBox from 'components/forms/FormBox'
import {useDispatch} from 'react-redux'
import {setPageTitle} from 'providers/slices/pageTitle'

export default ({
	control,
	error,
	formtype,
	name,
}) => {
	const {field} = useController({
		name,
		control,
		rules: {
			maxLength: {
				value: 64,
				message: 'Max length: 64'
			}
		}
	})

	const dispatch = useDispatch()
	const verbs = formtype === 'update' ? ['Updating', 'Update'] : ['Creating', 'Create']
	useEffect(() => {
		dispatch(setPageTitle(field.value ? `${verbs[0]} Event (${field.value})` : `${verbs[1]} Event`))
	}, [field.value])

	return (<>
		<FormBox>
			<TextField
				{...field}
				fullWidth
				size='small'
				variant='outlined'
				label='Event Name'
				error={!!error}
				helperText={error?.message}
				required
			/>
		</FormBox>
	</>)
}