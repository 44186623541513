import {useController} from 'react-hook-form'
import {TextField, IconButton} from '@mui/material'
import {ErrorOutline} from '@mui/icons-material'
import Tooltip from 'components/events/Tooltip'
import FormBox from 'components/forms/FormBox'

export default ({
	name,
	error,
	control
}) => {
	const {field} = useController({
		name,
		control,
		rules: {
			maxLength: {
				value: 255,
				message: 'Max length: 255'
			}
		}
	})

	return (<>
		<FormBox>
			<TextField
				{...field}
				fullWidth
				size='small'
				variant='outlined'
				label='E-mail'
				type='email'
				error={!!error?.message}
				helperText={error?.message}
				inputProps={{
					multiple: true
				}}
			/>
			<Tooltip title='This will be used to send sales reports. Separate multiple e-mail addresses with commas.'>
				<IconButton>
					<ErrorOutline />
				</IconButton>
			</Tooltip>
		</FormBox>
	</>)
}