/**
 * Generates a unique, URL-friendly slug from a given input string.
 * 
 * This function converts the input string to lowercase and replaces spaces and 
 * non-alphanumeric characters with dashes to create a base slug. It then checks 
 * the provided blacklist of existing slugs to ensure the generated slug is unique.
 * 
 * If the base slug already exists in the blacklist, a numeric suffix is appended 
 * (e.g., "-1", "-2") until a unique slug is found.
 * 
 * @param {string} input - The string to convert into a slug.
 * @param {string[]} [blacklist=[]] - An array of existing slugs to avoid duplication.
 * @returns {string} - A unique slug.
 */

export default () => (
	input,
	blacklist = []
) => {
	const baseSlug = input.toLowerCase().replace(/(^[^a-zA-Z0-9]+)|([^a-zA-Z0-9]+$)|([^a-zA-Z0-9]+)/g, (_, start, end) => start || end ? "" : "-")

	if (blacklist.length === 0) {
		return baseSlug
	}

	const blacklistSet = new Set(blacklist.map(item => item.toLowerCase()))

	let slug = baseSlug
	let counter = 1

	while (blacklistSet.has(slug.toLowerCase())) {
		slug = `${baseSlug}-${counter}`
		counter++
	}

	return slug
}