import {createTheme} from '@mui/material'
import {alpha, lighten, darken} from '@mui/material/styles'
import {colours} from 'data/colours'
import {eventStatuses} from 'data/eventOptions'
import {ticketOutlets} from 'data/ticketOutlets'

/**
 * cobalt
 */
const dark = '#121624'
const normal = '#1e2230'
const light = '#222634'
const primary = '#4E85E4'//'#1c58bf'
const secondary = '#7c4dff'
const info = '#1e88e5'
const success = '#00c853'
const error = '#f44336'
const warning = '#ffc107'


/**
 * green
 */
// const dark = '#131610'
// const normal = '#21241b'
// const light = '#363b30'
// const primary = '#91c4a1'//'#1c58bf'
// const secondary = '#739383'
// const info = '#6262ff'
// const success = '#3d8165'
// const error = '#ff6262'//'#f44336'
// const warning = '#ffcb82'


let theme = createTheme({
	shape: {
		borderRadius: 8
	},
	typography: {
		fontSize: 13,
		fontFamily: [
			// 'Lexend',
			'Nunito',
			'-apple-system',
			'BlinkMacSystemFont',
			'"Druid Sans"',
			'"Helvetica Neue"',
			'sans-serif'
		].join(','),
		h1: {
			fontSize: '2rem'
		},
		h2: {
			fontSize: '1.5rem'
		},
		h3: {
			fontSize: '1.375rem'
		},
		h4: {
			fontSize: '1.25rem'
		},
		h5: {
			fontSize: '1.125rem'
		},
		h6: {
			fontSize: '1rem'
		}
	},
	palette: {
		contrastThreshold: 3,
		mode: 'dark',
		light,
		dark,
		normal,
		primary: {
			main: primary,
			light: lighten(primary, .2),
			dark: darken(primary, .2)
		},
		secondary: {
			main: secondary,
			light: lighten(secondary, .2),
			dark: darken(secondary, .2)
		},
		background: {
			default: normal,
			paper: dark,
		},

		tlr: {
			main: colours.tlr.hex,
			contrastText: '#ffffff'
		},
		...eventStatuses.reduce((accumulator, current) => ({
			...accumulator,
			[current.slug]: {
				main: current.colours.hex,
				contrastText: current.colours.contrastText
			}
		}), {}),
		...ticketOutlets.reduce((accumulator, current) => ({
			...accumulator,
			[current.slug]: {
				main: current.colour,
				contrastText: '#ffffff',
				tonalOffset: .2
			}
		}), {})
	},
	components: {
		MuiListItemButton: {
			styleOverrides: {
				root: {
					'&.Mui-selected': {
						background: alpha(lighten(dark, .6), .16)
					},
					'&.Mui-selected:hover': {
						background: alpha(lighten(dark, .6), .24)
					},
					':hover': {
						background: alpha(lighten(dark, .6), .12)
					}
				}
			}
		},
		MuiSkeleton: {
			defaultProps: {
				animation: 'wave'
			}
		},
		MuiTooltip: {
			styleOverrides: {
				arrow: {
					color: lighten(normal, '.2')
				},
				tooltip: {
					background: lighten(normal, '.2')
				}
			}
		}
	}
})


theme = createTheme(theme, {
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					[theme.breakpoints.down('sm')]: {
						width: '100%'
					}
				}
			}
		},
		MuiDataGrid: {
			styleOverrides: {
				root: {
					'& .MuiDataGrid-cell .MuiSelect-select': {
						fontSize: theme.typography.button.fontSize
					}		
				}
			}
		}
	}
})



export default theme

export const getScrollbarStyles = () => ({
	"*::-webkit-scrollbar-track": {
		backgroundColor: dark
	},
	"*::-webkit-scrollbar-thumb": {
		backgroundColor: lighten(normal, .2)
	},
	"*::-webkit-scrollbar-corner": {
		backgroundColor: dark
	},
	"*::-webkit-scrollbar": {
		width: '.8rem'
	}
})
