import {useState, useEffect, useMemo, forwardRef} from 'react'
import {TransitionGroup} from 'react-transition-group'
import {useSelector} from 'react-redux'
import {db} from 'providers/firebase'
import {ref, update, onValue} from 'firebase/database'

import {
	Alert,
	Avatar,
	Box,
	Button,
	Chip,
	Collapse,
	IconButton,
	Stack,
	Tooltip,
	useTheme
} from '@mui/material'

import {
	DataGrid,
	GridActionsCellItem,
	GridEditInputCell,
	GridFooterContainer,
	GridPagination,
	GridRowEditStopReasons,
	GridRowModes
} from '@mui/x-data-grid'

import {
	Add,
	Check,
	Clear,
	Delete,
	Edit,
	Save
} from '@mui/icons-material'

import generateSlug from 'functions/generateSlug'
import stringToColor from 'functions/stringToColor'


const NameEditInputCell = forwardRef(({
	error,
	...props
}, ref) => {
	const theme = useTheme()
	
	return (
		<Tooltip
			arrow
			open={!!error}
			title={(
				<div style={{whiteSpace: 'pre-line'}}>
					{error}
				</div>
			)}
			slotProps={{
				arrow: {
					sx: {
						color: theme.palette.error.dark
					}
				},
				tooltip: {
					sx: {
						backgroundColor: theme.palette.error.dark,
						color: theme.palette.error.contrastText
					}
				}
			}}
		>
			<div>
				<GridEditInputCell
					{...props} 
					error={!!error}
					ref={ref}
				/>
			</div>
		</Tooltip>			
	)
})


let errorId = 0
let newRoleTempId = 0

export default () => {
	const [staffingData, setStaffingData] = useState({
		personnel: [],
		roles: []
	})
	const [loading, setLoading] = useState(true)
	const [errors, setErrors] = useState([])
	const [rowModesModel, setRowModesModel] = useState({})
	const [rows, setRows] = useState([])
	const verifiedUser = useSelector(state => state.auth.verifiedUser)

	const updateRowMode = (id, mode, extra) => {
		setRowModesModel(prevModel => ({
			...prevModel,
			[id]: {
				mode,
				...extra
			}
		}))
	}

	const userCanManagePersonnel = verifiedUser.permissions.includes('managePersonnel')
	const userCanManageRoles = verifiedUser.permissions.includes('manageRoles')

	const addError = ({
		code,
		message
	}) => {
		setErrors([
			...errors,
			{
				cleared: false,
				id: errorId++,
				message,
				title: code,
			}
		])
	}

	const clearError = id => setErrors(errors.filter(error => error.id !== id))


	useEffect(() => {
		const listener = onValue(
			ref(db, '/staffing'),
			snapshot => {
				setLoading(true)
				const {personnel, roles} = snapshot.val()
				setStaffingData({
					personnel: Object.entries(personnel).map(([key, object]) => ({
						slug: key,
						...object
					})),
					roles: Object.values(roles)
				})
				setLoading(false)
			}, error => {
				setLoading(false)
				addError(error)
			}
		)

		return () => listener()		
	}, [])
	

	useEffect(() => {
		setRows(staffingData.roles.map(({
			name,
			slug
		}) => ({
			id: slug,
			members: staffingData.personnel.filter(({roles}) => roles.includes(slug)),
			name
		})))
	}, [staffingData])


	const roleNameSet = useMemo(() => new Set(staffingData.roles.map(role => role.name.toLowerCase())), [staffingData.roles])


	const validateRoleName = ({
		newRoleName,
		currentRoleName = ''
	}) => {
		const errors = []
		const valueLower = newRoleName.toLowerCase()

		if (newRoleName.length < 3) {
			errors.push('Role names must be at least 3 characters.')
		}

		if (roleNameSet.has(valueLower) && valueLower !== currentRoleName.toLowerCase()) {
			errors.push('A role by that name already exists.')
		}

		if (/[^ a-zA-Z0-9().\-_À-ÖØ-öø-ÿ]/g.test(newRoleName)) {
			errors.push('Role names must only consist of alphanumeric characters, spaces, and the following symbols: "., -, _, (, )".')
		}

		return errors
	}

	// commit
	const handleSaveClick = id => () => updateRowMode(id, GridRowModes.View)	

	// clear
	const handleClearClick = (id, row) => () => {
		console.log(row.name)
		// if (row.name.length === 0 && row.isNew) {
		// 	setRows(rows.filter(row => row.id !== id))
		// 	return
		// }

		updateRowMode(id, GridRowModes.View, {
			ignoreModifications: true
		})
	}

	// enables editing
	const handleEditClick = id => () => updateRowMode(id, GridRowModes.Edit)

	// asks for confirmation before deleting
	const handleDeleteClick = id => () => updateRowMode(id, 'delete')	
	
	// deletion confirmed by the user, proceed
	const handleConfirmedDeleteClick = id => async () => {
		try {
			if (!userCanManageRoles) {
				throw({
					message: 'Insufficient permissions'
				})
			}
			await update(ref(db, `/staffing/roles`), {
				[id]: null
			})
		} catch (error) {
			addError(error)
		} finally {
			updateRowMode(id, GridRowModes.View)
		}
	}

	// deletion aborted by the user
	const handleCancelDeleteClick = id => () => updateRowMode(id, GridRowModes.View)

	const handleRowModesModelChange = newRowModesModel => setRowModesModel(newRowModesModel)

	const handleRowEditStop = (params, event) => {
		if (params.reason === GridRowEditStopReasons.rowFocusOut) {
			event.defaultMuiPrevented = true
		}
	}

	// commit logic
	const processRowUpdate = async newRow => {
		if (!userCanManageRoles) {
			addError({
				message: 'Insufficient permissions'
			})
			return newRow
		}

		// validation
		const errors = validateRoleName({
			newRoleName: newRow.name
		})

		if (errors.length > 0) {
			errors.forEach(error => addError({message: error}))
			return newRow
		}

		// update logic
		const existingRole = staffingData.roles.find(role => role.slug === newRow.id)
		if (existingRole) {
			try {
				await update(ref(db, `/staffing/roles/${newRow.id}`), {
					name: newRow.name
				})
			} catch (error) {
				console.error(error)
				addError(error)
			}
			
			return newRow
		} else {
			const slug = generateSlug(newRow.name, staffingData.roles.map(role => role.slug))
			
			const updatedRow = {
				...newRow,
				id: slug,
				isNew: false,
				slug
			}

			try {
				await update(ref(db, `/staffing/roles/${slug}`), {
					name: updatedRow.name,
					slug
				})
			} catch (error) {
				console.error(error)
				addError(error)
			}

			return updatedRow
		}
	}

	const handleProcessRowUpdateError = error => {
		console.error(error)
		addError(error)
	}


	const removeRoleFromMember = (memberSlug, roleId) => async event => {
		try {
			if (!userCanManagePersonnel) {
				throw({
					message: 'Insufficient permissions'
				})
			}

			const member = staffingData.personnel.find(({slug}) => slug === memberSlug)
			if (!member) {
				throw({
					message: `Role member not found`
				})
			}

			await update(ref(db, `/staffing/personnel/${memberSlug}`), {
				roles: member.roles.filter(role => role !== roleId)
			})
		} catch (error) {
			addError(error)
		}
	}


	const Footer = () => {
		const handleAddButtonClick = () => {
			if (!userCanManageRoles) {
				addError({
					message: 'Insufficient permissions'
				})
				return
			}

			const id = newRoleTempId++

			setRows(prevRows => ([
				{	
					id,
					name: '',
					isNew: true
				},
				...prevRows
			]))

			updateRowMode(id, GridRowModes.Edit, {
				fieldToFocus: 'name'
			})	
		}
	
		return (
			<GridFooterContainer sx={{mx: 1}}>
				<Button
					disabled={!userCanManageRoles}
					size='small'
					startIcon={<Add />}
					onClick={handleAddButtonClick}
				>
					Add new role
				</Button>
				<GridPagination />
			</GridFooterContainer>
		)
	}


	const columns = [{
		description: 'The name of the role',
		editable: true,
		field: 'name',
		flex: 1,
		headerName: 'Role name',
		hideable: false,
		minWidth: 180,
		preProcessEditCellProps: params => {
			const {row, props} = params

			const errors = validateRoleName({
				newRoleName: props.value,
				currentRoleName: row.name
			})

			return {
				...props,
				error: errors.join('\n')
			}
		},
		renderEditCell: params => <NameEditInputCell {...params} />
	},{
		description: 'The ID (slug) of the role',
		field: 'id',
		headerName: 'ID',
		flex: 2,
		hideable: true
	},{
		description: 'Personnel who currently have this role',
		field: 'members',
		flex: 2,
		headerName: 'Personnel',
		hideSortIcons: true,
		renderCell: ({
			row,
			value = []
		}) => (
			<Stack
				direction='row'
				spacing={1}
			>
				{value.map(({
					picture = '/#', //+
					name,
					slug,
				}) => (
					<Chip
						avatar={(
							<Avatar
								alt={name}
								src={picture}
								sx={{
									bgcolor: stringToColor(name)
								}}
							/>
						)}
						key={slug}
						label={name}
						onDelete={removeRoleFromMember(slug, row.id)}
						size='small'
						sx={{
							fontSize: '.75rem'
						}}
						variant='outlined'
					/>
				))}
			</Stack>
		)
	},{
		align: 'right',
		field: 'actions',
		getActions: ({
			id,
			row
		}) => (
			(rowModesModel[id]?.mode === GridRowModes.Edit ? ([
				<GridActionsCellItem
					icon={<Save />}
					label='Save'
					onClick={handleSaveClick(id)}
					disabled={!userCanManageRoles}
				/>,
				<GridActionsCellItem
					icon={<Clear />}
					label='Cancel'
					onClick={handleClearClick(id, row)}
					disabled={!userCanManageRoles}
				/>
			]) : rowModesModel[id]?.mode === 'delete' ? ([
				<GridActionsCellItem
					icon={<Check />}
					label='Confirm'
					onClick={handleConfirmedDeleteClick(id)}
					disabled={!userCanManageRoles}
				/>,
				<GridActionsCellItem
					icon={<Clear />}
					label='Reset'
					onClick={handleCancelDeleteClick(id)}
					disabled={!userCanManageRoles}
				/>
			]) : ([
				<GridActionsCellItem
					icon={<Edit />}
					label='Edit'
					onClick={handleEditClick(id)}
					disabled={!userCanManageRoles}
				/>,
				<GridActionsCellItem
					icon={<Delete />}
					label='Delete'
					onClick={handleDeleteClick(id)}
					disabled={!userCanManageRoles}
				/>
			]))
		),
		type: 'actions',
		width: 80
	}]

	

	return (
		<Box sx={{
			display: 'flex',
			flexDirection: 'column',
			gap: 2,
			height: '90%'
		}}>
			<DataGrid
				autoPageSize
				columns={columns}
				columnHeaderHeight={48}
				disableRowSelectionOnClick
				loading={loading}
				rows={rows}

				editMode='row'
				rowModesModel={rowModesModel}
				onRowModesModelChange={handleRowModesModelChange}
				onRowEditStop={handleRowEditStop}
				processRowUpdate={processRowUpdate}
				onProcessRowUpdateError={handleProcessRowUpdateError}

				columnVisibilityModel={{
					id: false
				}}

				slots={{
					footer: Footer
				}}
			/>

			<TransitionGroup>
				{errors.map(error => (
					<Collapse
						key={error.id}
						sx={{mb: 1}}
						unmountOnExit
					>
						<Alert
							action={(
								<IconButton
									aria-label='dismiss'
									color='inherit'
									size='small'
									onClick={() => clearError(error.id)}
									tabIndex={0}
								>
									<Clear fontSize='inherit' />
								</IconButton>
							)}
							severity='error'
							title={error.title}
						>
							{error.message}
						</Alert>
					</Collapse>
				))}
			</TransitionGroup>
		</Box>
	)
}