import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {setOpen} from 'providers/slices/sidebar'

import {db} from 'providers/firebase'
import {
	onValue,
	orderByChild,
	query,
	ref,
	remove,
	startAt,
	update
} from 'firebase/database'

import {
	Alert,
	AlertTitle,
	Box,
	Button,
	IconButton,
	Snackbar,
	Tooltip,
	useMediaQuery,
	useTheme
} from '@mui/material'

import {
	Accessible,
	Add,
	Check,
	Clear,
	Close,
	Delete,
	Edit,
	EventSeat,
	QuestionMark,
	Save
} from '@mui/icons-material'

import {
	DataGrid,
	GridActionsCellItem,
	GridFooterContainer,
	GridPagination,
	GridToolbarContainer,
	GridToolbarQuickFilter,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarDensitySelector,
	GridRowEditStopReasons,
	GridRowModes,
	useGridApiRef
} from '@mui/x-data-grid'

import sumSales from 'functions/sumSales'
import {formatDateVeryShort} from 'functions/formatDates'
import {rooms} from 'data/eventOptions'


let errorId = 0
let newResTempId = 0


const allowedStatuses = new Set([
	'onSale',
	'soldOut',
	'confirmed'
])


const size = (minWidth, maxWidth) => ({
	minWidth,
	maxWidth: maxWidth || minWidth
})


const YesNoToIcon = ({value}) => {
	if (value === 'Yes') {
		return <Check sx={{verticalAlign: 'middle'}} color='success' />
	} else if (value === 'No') {
		return <Close sx={{verticalAlign: 'middle'}} color='error' />
	} else {
		return <QuestionMark sx={{verticalAlign: 'middle'}} />
	}
}


const Toolbar = () => {
	const theme = useTheme()

	return (
		<GridToolbarContainer sx={{
			display: 'flex',
			justifyContent: 'space-between'
		}}>
			<Box sx={{
				display: 'flex',
				gap: 1
			}}>
				<GridToolbarColumnsButton />
				<GridToolbarFilterButton />
				<GridToolbarDensitySelector />
			</Box>
			<GridToolbarQuickFilter sx={{
				flexGrow: 1,
				maxWidth: '40%',
				mx: 1,
				pb: 0,
				'& input': {
					fontSize: theme.typography.button.fontSize
				}
			}} />
		</GridToolbarContainer>
	)
}


const Footer = ({
	apiRef,
	setRows,
	setRowModesModel
}) => {
	const verifiedUser = useSelector(state => state.auth.verifiedUser)
	const userCanManageAccessibilityReservations = verifiedUser.permissions.includes('manageAccessibilityReservations')

	const updateRowMode = (id, mode, extra) => {
		setRowModesModel(prevModel => ({
			...prevModel,
			[id]: {
				mode,
				...extra
			}
		}))
	}

	const handleAddButtonClick = () => {
		if (!userCanManageAccessibilityReservations) {
			return
		}

		apiRef.current.setPage(0)

		const id = newResTempId++

		setRows(prevRows => ([
			{
				id,
				eventName: '',
				seatNeeded: '',
				wheelchairAccess: '',
				isNew: true
			},
			...prevRows
		]))

		updateRowMode(id, GridRowModes.Edit, {
			fieldToFocus: 'eventName'
		})
	}

	return (
		<GridFooterContainer>
			{userCanManageAccessibilityReservations && (<>
				<Button
					size='small'
					startIcon={<Add />}
					onClick={handleAddButtonClick}
					variant='contained'
					sx={{
						mx: 1
					}}
				>
					Add new reservation
				</Button>
			</>)}
			<GridPagination />
		</GridFooterContainer>
	)
}




export default function AccessibilityReservations () {
	const apiRef = useGridApiRef()
	const dispatch = useDispatch()

	const [accessibilityReservations, setAccessibilityReservations] = useState({})
	const [rows, setRows] = useState([])
	const [loadingAccess, setLoadingAccess] = useState(true)
	const [loadingRows, setLoadingRows] = useState(true)
	const [errors, setErrors] = useState([])
	const [rowModesModel, setRowModesModel] = useState({})


	const updateRowMode = (id, mode, extra) => {
		setRowModesModel(prevModel => ({
			...prevModel,
			[id]: {
				mode,
				...extra
			}
		}))
	}

	const addError = error => {
		const {code, message} = error
		console.error(error)
		setErrors([
			...errors,
			{
				cleared: false,
				id: errorId++,
				message,
				title: code,
			}
		])
	}

	const clearError = id => setErrors(errors.filter(error => error.id !== id))

	const {events, loading: loadingEvents} = useSelector(state => state.events)
	const verifiedUser = useSelector(state => state.auth.verifiedUser)

	const loading = loadingEvents || loadingAccess || loadingRows

	const theme = useTheme()

	const userCanManageAccessibilityReservations = verifiedUser.permissions.includes('manageAccessibilityReservations')

	const generateAccessibilityReservationUid = (row, i = 0) => {
		if (i > 10) {
			addError({
				message: 'generateEventId tried too many times'
			})
			return
		}

		const uid = (Date.now() + Math.random()).toString(36).replace('.', '')

		if (Object.keys(accessibilityReservations?.[row.eventName] || {})?.includes(uid)) {
			i++
			return generateAccessibilityReservationUid(row, i)
		} else {
			return uid
		}
	}


	useEffect(() => {
		dispatch(setOpen(false))
	}, [])


	useEffect(() => {
		const now = Date.now()

		setLoadingAccess(true)

		const listener = onValue(
			query(
				ref(db, '/accessibility'),
				orderByChild('start'),
				startAt(now)
			),
			snapshot => {
				setAccessibilityReservations(snapshot.val() || [])
				setLoadingAccess(false)
			}, error => {
				setLoadingAccess(false)
				addError(error)
			}
		)

		return () => listener()
	}, [events])



	useEffect(() => {
		if (loadingAccess) {
			return
		}

		setLoadingRows(true)

		const eventMap = new Map(events
			.filter(event => allowedStatuses.has(event.status))
			.map(event => [event.uid, event])
		)

		const rows = []

		for (const [eventUid, reservations] of Object.entries(accessibilityReservations)) {
			const eventDetails = eventMap.get(eventUid)
			if (!eventDetails) continue

			for (const [reservationId, reservationData] of Object.entries(reservations)) {
				if (reservationId === 'start') continue

				rows.push({
					id: reservationId,
					uid: reservationId,
					...reservationData,
					date: eventDetails.start,
					eventUid: eventDetails.uid,
					// eventName: eventDetails.name,
					eventName: eventDetails.uid,
					room: eventDetails.room,
					sales: sumSales(eventDetails.sales)
				})
			}
		}

		setRows(rows)
		setLoadingAccess(false) //~
		setLoadingRows(false)
	}, [loading, events, accessibilityReservations])


	const columns = [{
		description: 'The date of the event',
		field: 'date',
		flex: 1,
		headerName: 'Date',
		...size(80),
		type: 'date',
		valueFormatter: value => formatDateVeryShort(value),
		valueGetter: value => value && new Date(value)
	}, {
		description: 'The event\'s uid',
		field: 'eventUid',
		flex: 1,
		headerName: 'Event UID'
	}, {
		description: 'The name of the event',
		editable: true,
		field: 'eventName',
		flex: 1.5,
		headerName: 'Event',
		type: 'singleSelect',
		getOptionValue: value => value.uid,
		getOptionLabel: value => value.name,
		valueOptions: events.filter(event => allowedStatuses.has(event.status)).map(event => ({
			name: event.name,
			uid: event.uid
		})),
		valueSetter: (value, row) => {
			const targetEvent = events.find(event => event.uid === value)
			return {
				...row,
				date: targetEvent?.start,
				eventName: value,
				eventUid: value,
				room: targetEvent?.room,
				sales: sumSales(targetEvent?.sales)
			}
		}
	}, {
		description: 'The room the event takes place in',
		disableColumnMenu: true,
		field: 'room',
		headerName: 'Room',
		...size(68),
		valueGetter: value => rooms.find(room => room.slug === value)?.name || value
	}, {
		description: 'The amount of presales the event has',
		disableColumnMenu: true,
		field: 'sales',
		headerName: 'Sales',
		...size(32, 64),
		type: 'number'
	}, {
		description: 'The customer\'s name',
		editable: true,
		field: 'customerName',
		headerName: 'Name',
		flex: 1.5
	}, {
		description: 'The customer\'s booking reference',
		field: 'bookingRef',
		headerName: 'Booking ref.',
		flex: 1.5,
		sortable: false,
	}, {
		description: 'The customer\'s email address',
		editable: true,
		field: 'customerEmail',
		headerName: 'Email address',
		flex: 1.5,
		sortable: false
	}, {
		description: 'The name of the customer\'s personal assistant',
		editable: true,
		field: 'paName',
		headerName: 'Name',
		flex: 1.5,
		sortable: false,
	}, {
		description: 'The relation of the customer\'s personal assistant to the customer',
		editable: true,
		field: 'paRelation',
		headerName: 'Relation',
		flex: 1.5,
		sortable: false,
	}, {
		description: 'Whether or not the customer has reserved a seat',
		disableColumnMenu: true,
		editable: true,
		field: 'seatNeeded',
		headerName: 'Seat',
		headerAlign: 'center',
		align: 'center',
		...size(48, 68),
		sortable: false,
		type: 'singleSelect',
		valueOptions: [{
			label: 'No',
			value: 'No',
		}, {
			label: 'Yes',
			value: 'Yes'
		}],
		getOptionValue: value => value.value,
		getOptionLabel: value => value.label,
		renderCell: ({value}) => <YesNoToIcon value={value} />,
		renderHeader: ({colDef}) => (
			<Tooltip title={colDef.description}>
				<EventSeat />
			</Tooltip>
		)
	}, {
		description: 'Whether or not the customer requires wheelchair access',
		disableColumnMenu: true,
		editable: true,
		field: 'wheelchairAccess',
		headerName: 'Wheelchair',
		headerAlign: 'center',
		align: 'center',
		...size(48, 68),
		sortable: false,
		type: 'singleSelect',
		valueOptions: [{
			label: 'No',
			value: 'No',
		}, {
			label: 'Yes',
			value: 'Yes'
		}],
		getOptionValue: value => value.value,
		getOptionLabel: value => value.label,
		renderCell: ({value}) => <YesNoToIcon value={value} />,
		renderHeader: ({colDef}) => (
			<Tooltip title={colDef.description}>
				<Accessible />
			</Tooltip>
		)
	}, {
		description: 'Any other assistance needs the customer has',
		editable: true,
		disableColumnMenu: true,
		field: 'otherAssistance',
		flex: 2.5,
		headerName: 'Other',
		sortable: false,
	}]

	if (userCanManageAccessibilityReservations) {
		columns.push({
			field: 'actions',
			type: 'actions',
			getActions: ({
				id,
				row
			}) => (
				(rowModesModel[id]?.mode === GridRowModes.Edit ? ([
					<GridActionsCellItem
						icon={<Save />}
						label='Save'
						onClick={handleSaveClick(id, row)}
					/>,
					<GridActionsCellItem
						icon={<Clear color='error' />}
						label='Cancel'
						onClick={handleClearClick(id, row)}
					/>
				]) : rowModesModel[id]?.mode === 'delete' ? ([
					<GridActionsCellItem
						icon={<Check color='success' />}
						label='Confirm'
						onClick={handleConfirmedDeleteClick(id, row)}
					/>,
					<GridActionsCellItem
						icon={<Clear color='error' />}
						label='Reset'
						onClick={handleCancelDeleteClick(id)}
					/>
				]) : ([
					<GridActionsCellItem
						icon={<Edit />}
						label='Edit'
						onClick={handleEditClick(id)}
					/>,
					<GridActionsCellItem
						icon={<Delete />}
						label='Delete'
						onClick={handleDeleteClick(id)}
					/>
				]))
			)
		})
	}


	const handleRowModesModelChange = newRowModesModel => {
		setRowModesModel(prevModel => ({
			...prevModel,
			...newRowModesModel
		}))
	}

	const handleRowEditStop = (params, event) => {
		if (params.reason === GridRowEditStopReasons.rowFocusOut) {
			event.defaultMuiPrevented = true
		}
	}

	// commit
	const handleSaveClick = (id) => () => updateRowMode(id, GridRowModes.View)

	// clear any changes to the row
	const handleClearClick = (id, row) => () => {
		if (row.isNew) {
			setRows(rows.filter(row => row.id !== id))
			return
		}

		updateRowMode(id, GridRowModes.View, {
			ignoreModifications: true
		})
	}

	// enable editing of the row
	const handleEditClick = id => () => updateRowMode(id, GridRowModes.Edit)

	// ask for confirmation before deleting
	const handleDeleteClick = id => () => updateRowMode(id, 'delete')

	// deletion confirmed by the user, proceed
	const handleConfirmedDeleteClick = (id, row) => async () => {
		try {
			if (!userCanManageAccessibilityReservations) {
				throw ({
					message: 'Insufficient permissions'
				})
			}

			await remove(ref(db, `/accessibility/${row.eventUid}/${row.uid}`))
		} catch (error) {
			addError(error)
		} finally {
			updateRowMode(id, GridRowModes.View)
		}
	}

	// deletion aborted by the user
	const handleCancelDeleteClick = id => () => updateRowMode(id, GridRowModes.View)


	const validateNewRow = newRow => {
		const fields = [{
			fieldName: 'customerName',
			fieldLabel: 'Customer name',
			maxLen: 30,
			regex: /^[\p{L}\p{M} \-\/&()]+$/u,
			required: true
		}, {
			fieldName: 'customerEmail',
			fieldLabel: 'Customer Email',
			regexError: 'Customer email isn`t valid',
			maxLen: 60,
			regex: /^[^@]+@[^@]+\.[^@]+$/
		}, {
			fieldLabel: 'Personal assistance name',
			fieldName: 'paName',
			maxLen: 30,
			regex: /^[\p{L}\p{M} \-\/&()]+$/u
		}, {
			fieldLabel: 'Personal assistant relation',
			fieldName: 'paRelation',
			maxLen: 30,
			regex: /^[\p{L}\p{M}\s'&\/\-.,:;!?()\[\]{}]*$/u
		}, {
			errorMsg: 'Seat needed field must be either Yes or No',
			fieldLabel: 'Seat needed',
			fieldName: 'seatNeeded'
		}, {
			errorMsg: 'Wheelchair access field must be either Yes or No',
			fieldLabel: 'Wheelchair access',
			fieldName: 'wheelchairAccess'
		}, {
			fieldLabel: 'Other',
			fieldName: 'otherAssistance',
			maxLen: 60,
			regex: /^[\p{L}\p{M}\s'&\/\-.,:;!?()\[\]{}]*$/u
		}]

		const errors = []

		for (const {
			fieldLabel,
			fieldName,
			maxLen,
			regex,
			regexError = `${fieldLabel} contains invalid characters`,
			required = false
		} of fields) {
			let trimmedValue = newRow[fieldName]?.trim()

			if (required && (!trimmedValue || trimmedValue.length === 0)) {
				errors.push(`${fieldLabel} is required`)
			}

			if (maxLen && trimmedValue && trimmedValue.length > maxLen) {
				errors.push(`${fieldLabel} exceeds maximum length of ${maxLen}`)
			}

			if (trimmedValue && trimmedValue.length > 0) {
				if (regex && !regex.test(trimmedValue)) {
					errors.push(regexError)
				}
			}

			if (fieldName === 'seatNeeded' || fieldName === 'wheelchairAccess') {
				if (trimmedValue !== 'Yes' && trimmedValue !== 'No') {
					trimmedValue = 'No'
				}
			}

			newRow[fieldName] = trimmedValue || ''

			if (fieldName === 'customerEmail' && newRow[fieldName]) {
				newRow[fieldName] = newRow[fieldName].toLowerCase()
			}
		}

		if (errors.length > 0) {
			errors.forEach(error => addError({message: error}))
			return false
		}

		return newRow
	}


	// commit logic
	const processRowUpdate = async (newRow, oldRow) => {
		if (!userCanManageAccessibilityReservations) {
			addError({
				message: 'Insufficient permissions'
			})
			return oldRow
		}

		const validatedRow = validateNewRow(newRow)

		if (!validatedRow) {
			updateRowMode(oldRow.id, GridRowModes.Edit)
			return newRow
		}

		if (validatedRow.isNew) {
			validatedRow.uid = generateAccessibilityReservationUid(validatedRow)
			validatedRow.paPass = validatedRow.paName ? 'Yes' : 'No'
			validatedRow.isNew = false
		}

		try {
			if (!validatedRow.eventUid) {throw 'Missing event uid'}
			if (!validatedRow.uid) {throw 'Missing row uid'}

			update(ref(db, `/accessibility/${validatedRow.eventUid}/${validatedRow.uid}`), {
				seatNeeded: validatedRow.seatNeeded,
				wheelchairAccess: validatedRow.wheelchairAccess,
				customerName: validatedRow.customerName,
				customerEmail: validatedRow.customerEmail,
				paName: validatedRow.paName,
				paRelation: validatedRow.paRelation,
				otherAssistance: validatedRow.otherAssistance
			})

			return validatedRow
		} catch (error) {
			addError(error)
			return oldRow
		}
	}


	const handleProcessRowUpdateError = error => addError(error)


	return (
		<Box sx={{
			height: `calc(100vh - ${theme.spacing(13)})`
		}}>
			<Box
				id='events-access-datagrid'
				sx={{
					height: '100%',
					display: 'flex'
				}}
			>
				<DataGrid
					apiRef={apiRef}
					// autoPageSize

					columnGroupingModel={[{
						groupId: 'Event details',
						children: [{
							field: 'date'
						}, {
							field: 'eventName'
						}, {
							field: 'eventUid'
						}, {
							field: 'room'
						}, {
							field: 'sales'
						}]
					}, {
						groupId: 'Customer details',
						children: [{
							field: 'bookingRef',
						}, {
							field: 'customerName'
						}, {
							field: 'customerEmail'
						}]
					}, {
						groupId: 'Personal assistant details',
						children: [{
							field: 'paName'
						}, {
							field: 'paRelation'
						}]
					}, {
						groupId: 'Assistance requested',
						children: [{
							field: 'seatNeeded'
						}, {
							field: 'wheelchairAccess'
						}, {
							field: 'otherAssistance'
						}]
					}]}
					columns={columns}
					editMode='row'
					initialState={{
						columns: {
							columnVisibilityModel: {
								bookingRef: false,
								eventUid: false,
								room: false
							}
						},
						density: 'compact',
						sorting: {
							sortModel: [{
								field: 'date',
								sort: 'asc'
							}]
						}
					}}
					loading={loading}
					rowHeight={42}
					rows={rows}
					slots={{
						footer: Footer,
						toolbar: Toolbar
					}}
					slotProps={{
						footer: {
							apiRef,
							setRows,
							setRowModesModel
						}
					}}
					rowModesModel={rowModesModel}
					onRowModesModelChange={handleRowModesModelChange}
					onRowEditStop={handleRowEditStop}
					processRowUpdate={processRowUpdate}
					onProcessRowUpdateError={handleProcessRowUpdateError}

				// sx={{fontSize: '.75rem'}}
				/>

				{errors.map(error => (
					<Snackbar
						anchorOrigin={{
							horizontal: 'center',
							vertical: 'bottom'
						}}
						key={error.id}
						open={true}
						autoHideDuration={10000}
						onClose={() => clearError(error.id)}
						TransitionProps={{
							unmountOnExit: true
						}}
					>
						<Alert
							action={(
								<IconButton
									aria-label='dismiss'
									color='inherit'
									size='small'
									onClick={() => clearError(error.id)}
									tabIndex={0}
								>
									<Clear fontSize='inherit' />
								</IconButton>
							)}
							onClose={() => clearError(error.id)}
							severity='error'
						>
							<AlertTitle>
								{error.title ?? 'Error'}
							</AlertTitle>
							{error.message}
						</Alert>
					</Snackbar>
				))}
			</Box>

		</Box>
	)
}