import {useController} from 'react-hook-form'
import {
	Box,
	TextField,
	MenuItem,
	IconButton,
	Typography
} from '@mui/material'
import {eventStatusesPrivate} from 'data/eventOptions'

import {HelpOutline} from '@mui/icons-material'
import EventStatusIcon from 'components/EventStatusIcon'
import Tooltip from 'components/events/Tooltip'
import FormBox from 'components/forms/FormBox'

const EventStatusTooltips = () => (
	<Tooltip
		title={
			eventStatusesPrivate.map(eventStatus => 
				<Box
					key={eventStatus.slug}
					sx={{
						py: 1,
						'& > :not(style)': {
							verticalAlign: 'middle',
							mr: .5
						}
					}}
				>
					<EventStatusIcon type={eventStatus.slug} />
					<Typography
						component='span'
						variant='subtitle2'
					>
						{eventStatus.name}
					</Typography>
					<br/>
					<Typography
						component='span'
						variant='caption'
					>
						{eventStatus.helpText}
					</Typography>
				</Box>
			)
		}
	>
		<span>
			<IconButton>
				<HelpOutline />
			</IconButton>
		</span>
	</Tooltip>
)


export default ({
	name,
	control
}) => {
	const {field} = useController({
		name,
		control
	})

	return (
		<FormBox>
			<TextField
				{...field}
				fullWidth
				required
				select
				variant='outlined'
				size='small'
				label='Event Status'
			>
				{eventStatusesPrivate.map(eventStatus => (
					<MenuItem
						value={eventStatus.slug}
						key={eventStatus.slug}
						sx={{
							'& > :not(style)': {
								mr: 1
							}
						}}
					>
						<EventStatusIcon
							type={eventStatus.slug}
							tooltip
							sx={{
								mr: 1,
								fontSize: 'small'
							}}
						/>
						<span style={{verticalAlign: 'middle'}}>
							{eventStatus.name}
						</span>
					</MenuItem>
				))}
			</TextField>
			<EventStatusTooltips />
		</FormBox>
	)
}