import CollapsibleCard from 'components/CollapsibleCard'
import DashboardCardMenuItem from 'components/dashboard/DashboardCardMenuItem'
import {
	CardContent,
	Divider,
	MenuList
} from '@mui/material'


export default () => (
	<CollapsibleCard
		variant='outlined'
		title='Useful Links'
		id='usefulLinks'
	>
		<CardContent>
			<MenuList dense>
				<DashboardCardMenuItem
					name='The Live Rooms Website'
					icon='iconoir:www'
					iconProps={{color: '#1c58bf'}}
					url='https://www.theliverooms.com'
				/>
				<DashboardCardMenuItem
					name='Chester Comedy Corner'
					icon='iconoir:www'
					iconProps={{color: '#9cb5ff'}}
					url='https://www.chestercomedycorner.com'
				/>
				<DashboardCardMenuItem
					name='TLR at Chester.com'
					icon='iconoir:www'
					url='https://chester.com/listing/the-live-rooms/'
				/>
				<DashboardCardMenuItem
					name='Webmail'
					icon='mdi:envelope'
					iconProps={{color: '#1c58bf'}}
					url='https://webmail.theliverooms.com'
				/>

				<Divider />

				<DashboardCardMenuItem
					name='Drive'
					icon='logos:google-drive'
					url='//drive.theliverooms.com/'
					/>
				<DashboardCardMenuItem
					name='Event Record'
					icon='mdi:google-spreadsheet'
					iconProps={{color: '#34a853'}}
					url='https://docs.google.com/spreadsheets/d/17XLbQGMYRhzQzGtaFskRDY3-Zt6LKt6wz_qoU5EUWHM/edit#gid=0'
				/>
				<DashboardCardMenuItem
					name='Calendar'
					icon='logos:google-calendar'
					url='//calendar.theliverooms.com/'
				/>

				<Divider />

				<DashboardCardMenuItem
					name='Facebook'
					icon='uil:facebook'
					iconProps={{color: '#3b5998'}}
					url='https://www.facebook.com/theliverooms/'
				/>
				<DashboardCardMenuItem
					name='Twitter'
					icon='mdi:twitter'
					iconProps={{color: '#00acee'}}
					url='https://www.twitter.com/the_liverooms'
				/>
				<DashboardCardMenuItem
					name='Instagram'
					icon='skill-icons:instagram'
					url='https://www.instagram.com/theliverooms/'
				/>

				<Divider />
				
				<DashboardCardMenuItem
					name='See Tickets'
					icon='noto:ticket'
					url='https://www.seetickets.com/venue/the-live-rooms/5437'
				/>
				<DashboardCardMenuItem
					name='See Tickets (Client Console)'
					icon='noto:ticket'
					url='https://clients.seetickets.com/'
				/>
				<DashboardCardMenuItem
					name='Skiddle (Console)'
					icon='noto:ticket'
					url='https://beta.promotioncentre.co.uk/'
				/>
			</MenuList>
		</CardContent>
	</CollapsibleCard>
)