import {useController} from 'react-hook-form'
import {TextField} from '@mui/material'
import FormBox from 'components/forms/FormBox'

export default ({
	control,
	error,
	name,
	...props
}) => {
	const {field} = useController({
		name,
		control,
		rules: {
			maxLength: {
				value: 64,
				message: 'Max length: 64'
			}
		}
	})

	return (<>
		<FormBox>
			<TextField
				{...field}
				fullWidth
				size='small'
				variant='outlined'
				label='Website Display Name'
				error={!!error}
				helperText={error?.message}
				{...props}
			/>
		</FormBox>
	</>)
}