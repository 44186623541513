import {useSelector} from 'react-redux'


/**
 * A utility function to check if a user has the necessary permissions and authentication status.
 *
 * @param {Object} params - The parameters for this function.
 * @param {Object} params.user - The user object containing permission and authentication information.
 * @param {Array<string>|string} [params.permission=[]] - A list of required permissions. If a single permission is provided as a string, it is converted into an array. The function will return `true` if the user has at least one of the specified permissions, unless `requireAuthOnly` is true.
 * @param {boolean} [params.requireAuthOnly=false] - If true, the function returns `true` if the user is authenticated, regardless of specific permissions.
 * @param {boolean} [params.ignoreAdmin=false] - If true, the admin permission check is ignored, meaning that an admin user will not automatically be granted access.
 *
 * @returns {boolean} - Returns `true` if the user meets the permission requirements; otherwise, returns `false`.
 *
 * The function performs the following checks:
 * 1. If `requireAuthOnly` is true, it returns `true` if the user is authenticated (`user.verified`), regardless of permissions.
 * 2. If `ignoreAdmin` is false and the user has the 'admin' permission, it grants access.
 * 3. If `requireAuthOnly` is false and the user's permissions include at least one of the required permissions specified in `permission`, it grants access.
 * 4. If the user's permissions are not available (`!user?.permissions`), it returns `false`.
 */
export const checkPermission = ({
	user,
	permission = [],
	requireAuthOnly,
	ignoreAdmin
}) => {
	if (!user?.permissions) {
		return false
	}

	if (typeof permission === 'string') {
		permission = [permission]
	}

	return (
		requireAuthOnly
		|| (!ignoreAdmin && user.permissions.includes('admin'))
		|| (permission.some(requiredPermission => user.permissions.includes(requiredPermission)))
	)
}


/**
 * A component that conditionally renders its children based on user permissions and authentication status.
 *
 * @param {Object} props - The properties for this component.
 * @param {Array<string>|string} [props.permission=[]] - A list of required permissions. If a single permission is provided as a string, it is converted into an array. The component will render its children only if the user has at least one of the specified permissions, unless `requireAuthOnly` is true.
 * @param {boolean} [props.requireAuthOnly=false] - If true, the component will render its children if the user is authenticated, regardless of specific permissions.
 * @param {boolean} [props.ignoreAdmin=false] - If true, the admin permission check is ignored, meaning that an admin user will not automatically be granted access.
 * @param {ReactNode} props.children - The content to render if the permission checks pass.
 *
 * @returns {ReactNode|null} - Returns the children if the user meets the permission requirements; otherwise, returns null.
 *
 * The function uses the `useSelector` hook to access the user's authentication and permission data from the Redux store. It performs the following checks:
 * 1. If `requireAuthOnly` is true, it renders the children if the user is authenticated (`verified`), regardless of permissions.
 * 2. If `ignoreAdmin` is false, it grants access to users with the 'admin' permission.
 * 3. If `requireAuthOnly` is false and the user's permissions include at least one of the required permissions specified in `permission`, it grants access.
 * 4. If none of the conditions are met, it does not render the children.
 */
export default function CheckPermission({
	permission = [],
	requireAuthOnly,
	ignoreAdmin,
	children
}) {
	const {
		verified,
		verifiedUser
	} = useSelector(state => state.auth)

	if (typeof permission === 'string') {
		permission = [permission]
	}

	if (!verifiedUser?.permissions) {
		return null
	}

	if (
		(requireAuthOnly && verified)
	 || (!ignoreAdmin && verifiedUser.permissions.includes('admin'))
	 || (permission.some(requiredPermission => verifiedUser.permissions.includes(requiredPermission)))
	) {
		return children
	} else {
		return null
	}
}