import {useSelector} from 'react-redux'

import DashboardPage from 'pages/Dashboard'
import AddNewEventPage from 'pages/events/AddNewEvent'
import ViewEvents from 'pages/events/ViewEvents'
import ViewEventsPast from 'pages/events/ViewPastEvents'
import ViewEventHandler from 'pages/events/ViewEventHandler'
import EditEventHandler from 'pages/events/EditEventHandler'
import AccessibilityReservations from 'pages/events/AccessibilityReservations'

import Staffing from 'pages/staffing/Staffing'
// import EventsRotaA from 'pages/staffing/EventsRotaA'
// import TechnicalRota from 'pages/staffing/TechnicalRota'
import Personnel from 'pages/staffing/Personnel'
import Roles from 'pages/staffing/Roles'
import AddStaffMember from 'pages/staffing/AddStaffMember'

import UsersPage from 'pages/Users'

import Testing from 'pages/testing/Testing'
// import TestingChecklists from 'pages/testing/TestingChecklists'
// import TestingRTK from 'pages/testing/TestingRTK'
// import TestingRandom from 'pages/testing/TestingRandom'

import Settings from 'pages/Settings'
import NotFoundPage from 'pages/404'
import NewUserPage from 'pages/NewUser'


export const paths = [{
	element: <DashboardPage />,
	name: 'Dashboard',
	path: '/',
	permissions: ['viewEvents']
}, {
	element: <ViewEvents />,
	name: 'Events',
	path: '/events',
	permissions: ['viewEvents']
}, {
	element: <ViewEventsPast />,
	name: 'Events (Past)',
	path: '/past-events',
	permissions: ['viewEvents']
}, {
	element: <AddNewEventPage />,
	name: 'Create Event',
	path: '/events/create',
	permissions: ['viewEvents', 'manageEvents']
}, {
	element: <AccessibilityReservations />,
	name: 'Accessibility Reservations',
	path: '/events/accessibility-reservations',
	permissions: ['viewAccessibilityReservations', 'manageAccessibilityReservations']
}, {
	element: <ViewEventHandler />,
	name: 'View Event',
	path: '/events/:uid',
	permissions: ['viewEvents']
}, {
	element: <EditEventHandler />,
	name: 'Edit Event',
	path: '/events/:uid/edit',
	permissions: ['viewEvents', 'manageEvents']
}, {
	element: <Staffing />,
	name: 'Staffing',
	path: '/staffing',
	permision: 'testing',
	permissions: ['testing', 'managePersonnel', 'manageRoles', 'manageRota', 'viewPersonnel', 'viewRoles', 'viewRota']
}, {
	element: <Personnel />,
	name: 'Personnel',
	path: '/staffing/personnel',
	permissions: ['testing', 'managePersonnel', 'viewPersonnel']
}, {
	element: <Roles />,
	name: 'Roles',
	path: '/staffing/roles',
	permissions: ['testing', 'manageRoles', 'viewRoles']
}, {
	element: <AddStaffMember />,
	name: 'Add Staff Member',
	path: '/staffing/add-staff-member',
	permissions: ['managePersonnel']
}, {
	element: <UsersPage />,
	name: 'Users',
	path: '/users',
	permissions: ['viewUsers', 'manageUsers']
}, {
	element: <Testing />,
	name: 'Testing / Dev',
	path: '/testing',
	permissions: ['testing']
}, {
	element: <Settings />,
	name: 'Settings',
	path: '/settings',
	requiresAuthOnly: true
}, {
	element: <NotFoundPage />,
	name: 'Page Not Found',
	path: '/not-found',
	requiresAuthOnly: true
}, {
	element: <NewUserPage />,
	name: 'Welcome',
	path: '/new-user',
	requiresAuthOnly: true
}]


export const usePaths = () => {
	const verifiedUser = useSelector(state => state.auth.verifiedUser)
	return verifiedUser && paths.filter(path => (path.requiresAuthOnly || path.permissions.some(requiredPermission => verifiedUser.permissions.includes(requiredPermission))))
}