import {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import dayjs from 'dayjs'
import CheckPermission from 'functions/CheckPermission'
import UsefulLinksCard from 'components/dashboard/UsefulLinksCard'
import UpcomingEventsCard from 'components/dashboard/UpcomingEventsCard'
import EventCard from 'components/dashboard/EventCard'
import TicketSalesCard from 'components/dashboard/TicketSalesCard'
import ActionsCard from 'components/dashboard/ActionsCard'
import {Grid} from '@mui/material'


const GridItem = ({
	children,
	...props
}) => (
	<Grid
		item
		xl={3}
		lg={4}
		md={6}
		sm={12}
		xs={12}
		{...props}
	>
		{children}
	</Grid>
)


export default () => {
	const [filteredEvents, setFilteredEvents] = useState(null)
	const [nextEvents, setNextEvents] = useState([])
	const {events, loading} = useSelector(state => state.events)

	useEffect(() => {
		if (!loading) {
			setFilteredEvents(events.filter(event =>
				event.status !== 'cancelled'
				&& event.status !== 'postponed'
			))
		}
	}, [events, loading])

	useEffect(() => {
		if (!filteredEvents || filteredEvents.length === 0) {return}
		const nextEventDate = dayjs(filteredEvents[0].start)
		setNextEvents(filteredEvents.filter(event => {
			const start = dayjs(event.start)
			return start.isSame(nextEventDate, 'day')
		}))
	}, [filteredEvents])

	return (<>
		<CheckPermission permission='manageEvents'>
			<ActionsCard />
		</CheckPermission>

		<Grid container spacing={2}>
			<CheckPermission permission='viewEvents'>
				{nextEvents.map(event => (
					<GridItem key={event.uid}>
						<EventCard event={event} />
					</GridItem>
				))}
			</CheckPermission>

			<CheckPermission permission='viewDetailedSales'>
				<GridItem>
					<TicketSalesCard defaultEvent={nextEvents[0]?.uid} />
				</GridItem>
			</CheckPermission>

			<CheckPermission permission='viewEvents'>
				<GridItem>
					<UpcomingEventsCard />
				</GridItem>
			</CheckPermission>

			<GridItem>
				<UsefulLinksCard />
			</GridItem>
		</Grid>
	</>)
}