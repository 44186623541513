import {Button} from '@mui/material'
import {Link} from 'wouter'

export default function LinkButton({
	href = '/',
	title = '',
	children,
	...props
}) {
	return (
		<Button
			href={href}
			component={Link}
			{...props}
		>
			{title || children}
		</Button>
	)
}