import packageJson from 'packageJson'
import useSignOut from 'functions/useSignOut'

export default async () => {
	const storedVersion = localStorage.getItem('version')
	const currentVersion = packageJson.version

	if (storedVersion !== currentVersion) {
		localStorage.setItem('version', currentVersion)
		const signOut = useSignOut()
		await signOut()
		window.location.reload(true)
	}
}