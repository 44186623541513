import {useState, Fragment} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useRoute} from 'wouter'

import {setOpen} from 'providers/slices/sidebar'

import Link from 'components/Link'
import CheckPermission from 'functions/CheckPermission'
import useSignOut from 'functions/useSignOut'

import {
	Box,
	Collapse,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Tooltip,
	useMediaQuery,
	useTheme
} from '@mui/material'

import {
	AccessibilityNew,
	AddCircleOutline,
	Assignment,
	Build,
	Dashboard,
	DateRange,
	EventNote,
	ExpandLess,
	ExpandMore,
	Groups3,
	History,
	Logout,
	Person,
	PersonAdd,
	People,
	Settings,
	SupervisedUserCircle
} from '@mui/icons-material'


const items = [{
	icon: <Dashboard />,
	label: 'Dashboard',
	permissions: ['viewEvents'],
	slug: 'dashboard',
	to: '/'
}, {
	icon: <DateRange />,
	label: 'Events',
	permissions: ['manageEvents', 'viewEvents'],
	slug: 'events',
	to: '/events',
	children: [{
		icon: <AddCircleOutline />,
		label: 'Create',
		permissions: ['manageEvents'],
		slug: 'createEvent',
		to: '/events/create'
	},{
		icon: <EventNote />,
		label: 'View',
		permissions: ['viewEvents'],
		slug: 'viewEvents',
		to: '/events'
	},{
		icon: <History />,
		label: 'View (Past)',
		permissions: ['viewEvents'],
		slug: 'viewEventsPast',
		to: '/past-events'
	},{
		icon: <AccessibilityNew />,
		label: 'Accessibility Reservations',
		permissions: ['viewAccessibilityReservations', 'manageAccessibilityReservations'],
		slug: 'accessibilityReservations',
		to: '/events/accessibility-reservations'
	},{
		icon: <Assignment />,
		label: 'Event Tasks',
		slug: 'eventTasks',
		permissions: ['manageEvents'],
		to: '#',
		disabled: true
	}]
// }, {
// 	icon: <Groups3 />,
// 	label: 'Staffing',
// 	permissions: ['managePersonnel', 'manageRoles', 'manageRota', 'viewPersonnel', 'viewRoles', 'viewRota'],
// 	slug: 'staffing',
// 	to: '/staffing',
// 	devOnly: true,
// 	children: [{
// 		icon: <Person />,
// 		label: 'Personnel',
// 		permissions: ['managePersonnel', 'viewPersonnel'],
// 		slug: 'personnel',
// 		to: '/staffing/personnel',
// 		devOnly: true
// 	},{
// 		icon: <SupervisedUserCircle />,
// 		label: 'Roles',
// 		permissions: ['manageRoles', 'viewRoles'],
// 		slug: 'roles',
// 		to: '/staffing/roles',
// 		devOnly: true
// 	},{
// 		icon: <PersonAdd />,
// 		label: 'Add Staff Member',
// 		permissions: ['managePersonnel'],
// 		slug: 'addStaffMember',
// 		to: '/staffing/add-staff-member',
// 		devOnly: true
// 	}]
}, {
	icon: <People />,
	label: 'Users',
	permissions: ['viewUsers', 'manageUsers'],
	slug: 'users',
	to: '/users'
}, {
	icon: <Build />,
	label: 'Testing',
	permissions: ['testing'],
	slug: 'testing',
	to: '/testing',
	children: []
},{
	DividerBefore: true,
	icon: <Settings />,
	label: 'Settings',
	requireAuthOnly: true,
	slug: 'settings',
	to: '/settings',
	devOnly: true
}]


const NavIcon = ({
	icon,
	...props
}) => (
	<ListItemIcon
		sx={{
			minWidth: 36,
			'& .MuiSvgIcon-root': {
				fontSize: 20
			}
		}}
		{...props}
	>
		{icon}
	</ListItemIcon>
)


const NavItem = ({
	child,
	children,
	icon,
	label,
	sx,
	to = '#',
	tooltipLabel = label,
	devOnly,
	...props
}) => {
	const [expanded, setExpanded] = useState(!!children)
	const theme = useTheme()
	const dispatch = useDispatch()
	const smallViewport = useMediaQuery(theme.breakpoints.down('md'))
	const [isActive] = useRoute(to)
	const drawerOpen = useSelector(state => state.sidebar.open)
	const toggleExpanded = () => setExpanded(!expanded)
	const closeDrawer = () => dispatch(setOpen(false))
	const expandable = (drawerOpen && !!children)

	const handleOnClick = () => {
		if (smallViewport && drawerOpen) {
			closeDrawer()
		} else {
			return
		}
	}

	return (<>
		<ListItem
			dense
			disableGutters
			sx={{
				py: 0,
				pr: 0,
				...sx
			}}
			secondaryAction={(children && drawerOpen) && (
				<IconButton
					size='small'
					onClick={toggleExpanded}
					sx={{
						mr: 1
					}}
				>
					{expanded ? <ExpandLess /> : <ExpandMore />}
				</IconButton>
			)}
			{...props}
		>
			{drawerOpen ? (
				<ListItemButton
					component={Link}
					onClick={handleOnClick}
					selected={isActive}
					sx={child && {pl: 4}}
					to={to}
					disabled={devOnly && process.env.NODE_ENV !== 'development'}
				>
					<NavIcon icon={icon} />
					<ListItemText primary={label}/>
				</ListItemButton>
			) : (
				<Tooltip
					arrow
					placement='right'
					slotProps={{
						tooltip: {
							sx: {
								marginLeft: '0 !important',
							}
						}
					}}
					title={tooltipLabel}
				>
					<ListItemButton
						component={Link}
						selected={isActive}
						to={to}
						disabled={devOnly && process.env.NODE_ENV !== 'development'}
					>
						<NavIcon icon={icon} />
						<ListItemText
							primary={label}
							primaryTypographyProps={{visibility: 'hidden'}}
						/>
					</ListItemButton>
				</Tooltip>
			)}
		</ListItem>
		{(expandable || !drawerOpen) && (
			<Collapse in={expanded} timeout='auto'>
				{children}
			</Collapse>
		)}
	</>)
}


const Submenu = ({
	children = [],
	label,
	icon,
	to,
	devOnly,
	...props
}) => {
	const [tooltipOpen, setTooltipOpen] = useState(false)
	const openTooltip = () => setTooltipOpen(true)
	const closeTooltip = () => setTooltipOpen(false)
	
	return (
		<Tooltip
			arrow
			placement='right-start'
			slotProps={{
				tooltip: {
					sx: {
						marginLeft: '0 !important',
						p: 0
					}
				}
			}}
			open={tooltipOpen}
			onOpen={openTooltip}
			onClose={closeTooltip}
			title={(
				<List component='div' disablePadding>
					{children.map(({
						icon,
						label,
						permissions,
						requireAuthOnly,
						slug,
						to,
						tooltipLabel = label,
						devOnly
					}) => (
						<Fragment key={slug}>
							<CheckPermission
								permission={permissions}
								requireAuthOnly={requireAuthOnly}
							>
								<ListItem
									key={`${slug}-item`}
									dense
									disableGutters
									sx={{
										p: 0
									}}
								>
									<ListItemButton
										component={Link}
										onClick={closeTooltip}
										sx={{
											px: 1.5,
											py: 0
										}}
										to={to}
										disabled={devOnly && process.env.NODE_ENV !== 'development'}
									>
											<ListItemIcon sx={{
												minWidth: 28,
												'& .MuiSvgIcon-root': {
													fontSize: 16
												}
											}} >
												{icon}
											</ListItemIcon>
										<ListItemText
											primary={tooltipLabel}
											primaryTypographyProps={{
												variant: 'caption'
											}}
										/>
									</ListItemButton>
								</ListItem>
							</CheckPermission>
						</Fragment>
					))}
				</List>
			)}
			{...props}
		>
			<ListItemButton
				component={Link}
				to={to}
				disabled={devOnly && process.env.NODE_ENV !== 'development'}
			>
				<NavIcon icon={icon} />
			</ListItemButton>
		</Tooltip>
	)
}


export default () => {
	const signOut = useSignOut()
	const drawerOpen = useSelector(state => state.sidebar.open)

	return (
		<Box sx={{flexGrow: 1}}>
			<List component='nav'>
				{items.map(({
					children,
					disabled,
					DividerAfter,
					DividerBefore,
					icon,
					label,
					permissions,
					requireAuthOnly,
					slug,
					to,
					devOnly
				}) => (
					<Fragment key={slug}>
						<CheckPermission
							requireAuthOnly={requireAuthOnly}
							permission={permissions}
						>
							{DividerBefore && <Divider sx={{my: 1}} />}
							{children ? (
								drawerOpen ? (
									<NavItem
										icon={icon}
										label={label}
										to={to}
										devOnly={devOnly}
									>
										{children.map(({
											disabled,
											icon,
											label,
											permissions,
											slug,
											to
										}) => (
											<Fragment key={slug}>
												<CheckPermission
													requireAuthOnly={requireAuthOnly}
													permission={permissions}
												>
													<NavItem
														child
														icon={icon}
														key={slug}
														label={label}
														to={to}
														devOnly={devOnly}
														disabled={disabled}
													/>
												</CheckPermission>
											</Fragment>
										))}
									</NavItem>
								) : (
									<ListItem
										disableGutters
										sx={{
											py: 0,
											pr: 0,
										}}
									>
										<Submenu
											label={label}
											to={to}
											icon={icon}
											devOnly={devOnly}
										>
											{children}
										</Submenu>
									</ListItem>
								)
							) : (
								<NavItem
									icon={icon}
									label={label}
									to={to}
									devOnly={devOnly}
									disabled={disabled}
								/>
							)}
							{DividerAfter && <Divider sx={{my: 1}} />}
						</CheckPermission>
					</Fragment>
				))}

				<NavItem
					key='logout'
					icon={<Logout />}
					label='Logout'
					onClick={signOut}
				/>
			</List>
		</Box>
	)
}